import axios from "axios"
if (localStorage.getItem("userAuthEtudiant")) {
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var token = userAuthEtudiant.user.api_token
}
export default axios.create({
  withCredentials: true,
  baseURL: `https://ismsf.scolarite.backcresus-institut.ovh/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
    'X-Frame-Options' : "DENY",
    apipassword: "3KbmGc5tZhuDbPtb",
  },
})
