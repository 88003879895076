import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";

const AddDemandeStage = props => {
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  var annee_id = userAuthEtudiant.user.annee_id
  var cycle_id = userAuthEtudiant.user.cycle_id
  var niveau_id = userAuthEtudiant.user.niveau_id
  const [disbutReg, setDisbutReg] = useState(true)
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [titre, setTitre] = useState("");
  const [dureeStage, setDureeStage] = useState("");
  const [file, setFile] = useState(null)
  const save = async () => {
    if(titre == ""){
      setDisbutReg(true)
      toast.error("⛔ Titre est obligatoire !", {
        containerId: "A",
      })
    }else if(dureeStage==""){
      setDisbutReg(true)
      toast.error("⛔ Durée de stage est obligatoire !", {
        containerId: "A",
      })
    }else if(file == null){
      setDisbutReg(true)
      toast.error("⛔ Fichier de stage est obligatoire !", {
        containerId: "A",
      })
    }else if (selectEnseignant == ""){
      setDisbutReg(true)
      toast.error("⛔ Enseignant est obligatoire !", {
        containerId: "A",
      })
    }else{
      const formData = new FormData();
      formData.append('enseignant_id', selectEnseignant.value);
      formData.append('titre', titre);
      formData.append('durée_du_stage', dureeStage);
      formData.append('etudiant_id', etudiant_id);
      formData.append('fichier_stage', file);
      formData.append('annee_id', annee_id);
      formData.append('cycle_id', cycle_id);
      formData.append('niveau_id', niveau_id);
      const res = await API.post("etudiant/store_stage", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }}).then(res => {
        if(res.data.errNum == 500){
          setDisbutReg(true)
          toast.error("⛔  Vous avez déjà fait une demande et votre demande a été accepter !", {
            containerId: "A",
          })
        }else{
          props.setSection(1)
        }
      })
    }
  }
  useEffect(async () => {
    if(cycle_id == 2 && niveau_id == 2){
      const res = await API.get("enseignant/get_ens_master").then(res => {
        setEnseignant(res.data.enseignant)
      })
    }else if( cycle_id == 1 && niveau_id == 3){
      const res = await API.get("enseignant/select").then(res => {
        setEnseignant(res.data.select_enseignant)
      })
    }
  }, [])
  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }
  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Titre du sujet")} :
                  </Label>
                  <Input
                      lg="3"
                      type="text"
                      className="form-control"
                      placeholder={props.t("Titre du sujet")}
                      onChange={e => setTitre(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Durée du sujet")} :
                  </Label>
                  <Input
                      lg="3"
                      type="text"
                      className="form-control"
                      placeholder={props.t("Durée du sujet")}
                      onChange={e => setDureeStage(e.target.value)}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Enseignant")}
                  </Label>
                  <Select
                      options={enseignant}
                      isSearchable={true}
                      value={selectEnseignant}
                      onChange={e => setSelectEnseignant(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">{cycle_id == 2 && niveau_id == 2 ? (("Projet mastère (PDF)")) : (("Projet PFE (PDF) "))} :</Label>
                  <Input
                      onChange={e => addFile(e)}
                      className="form-control"
                      type="file"
                      id="formFile"
                      accept=".pdf, .PDF"
                  />
                </div>
              </Col>
            </Row>

          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={save}
                  disabled={!disbutReg}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddDemandeStage)
AddDemandeStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
