import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState, useMemo } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Input,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import APIS from "../../apis"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
import cresusFond from "../../assets/images/cresuFond.jpg"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Grid } from "react-loader-spinner"

const EditIns = props => {
  const [disbutReg, setDisbutReg] = useState(true)
  const [display, setDisplay] = useState(0)
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [gouvernorat, setGouvernorat] = useState([])
  const [selectGouvernorat, setSelectGouvernorat] = useState("")
  const [mention, setMention] = useState([])
  const [selectMention, setSelectMention] = useState("")
  const [session, setSession] = useState([])
  const [selectSession, setSelectSession] = useState("")
  const [section, setSection] = useState([])
  const [selectSection, setSelectSection] = useState("")
  const [typeInscription, setTypeInscription] = useState([]);
  const [selectTypeInscription, setSelectTypeInscription] = useState("");
  const [prenomFr, setPrenomFr] = useState("");
  const [prenomAr, setPrenomAr] = useState("");
  const [nomFr, setNomFr] = useState("");
  const [nomAr, setNomAr] = useState("");
  const [prenomPereFr, setPrenomPereFr] = useState("");
  const [prenomPereAr, setPrenomPereAr] = useState("");
  const [prenomMereFr, setPrenomMereFr] = useState("");
  const [prenomMereAr, setPrenomMereAr] = useState("");
  const [nomMereFr, setNomMereFr] = useState("");
  const [nomMereAr, setNomMereAr] = useState("");
  const [professionPereFr, setProfessionPereFr] = useState("");
  const [professionPereAr, setProfessionPereAr] = useState("");
  const [professionMereFr, setProfessionMereFr] = useState("");
  const [professionMereAr, setProfessionMereAr] = useState("");
  const [telephonePere, setTelephonePere] = useState("");
  const [telephoneMere, setTelephoneMere] = useState("");
  const [sexe, setSexe] = useState(1);
  const [date, setDate] = useState("");
  const [lieuNaissanceFr, setLieuNaissanceFr] = useState("");
  const [lieuNaissanceAr, setLieuNaissanceAr] = useState("");
  const [selectNationnalite, setSelectNationnalite] = useState("");
  const options = useMemo(() => countryList().getData(), [])
  const [cin, setCin] = useState("");
  const [etatCivil, setEtatCivil] = useState(1);
  const [inscription, setInscription] = useState(1);
  const [rueFr, setRueFr] = useState("");
  const [rueAr, setRueAr] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [accreditationFr, setAccreditationFr] = useState("");
  const [accreditationAr, setAccreditationAr] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [anneeBac, setAnneeBac] = useState("");
  const [lyceeFr, setLyceeFr] = useState("");
  const [lyceeAr, setLyceeAr] = useState("");
  const [remarque, setRemarque] = useState("");
  const [nomPereFr, setNomPereFr] = useState("");
  const [nomPereAr, setNomPereAr] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false)
  const [selectAnnee, setSelectAnnee] = useState("");
  const [EtudiantId, setEtudiantId] = useState("");
  const [typeInstrument, setTypeInstrument] = useState([]);
  const [selectTypeInstrument, setSelectTypeInstrument] = useState("");
  const [typeInstrument1, setTypeInstrument1] = useState([]);
  const [selectTypeInstrument1, setSelectTypeInstrument1] = useState("");
  const [annee, setAnnee] = useState("")

  useEffect(async () => {
    const resType = await APIS.get("type/instrument/select").then(resType => {
      setTypeInstrument(resType.data.TypeInstrument)
    })
    const resType1 = await APIS.get("type/instrument/select").then(resType1 => {
      setTypeInstrument1(resType1.data.TypeInstrument)
    })
    const resC = await APIS.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
    const res = await APIS.get("gouvernorat/select").then(res => {
      setGouvernorat(res.data.Gouvernorat)
    })
    const resM = await APIS.get("mention/select").then(res => {
      setMention(res.data.Mention)
    })
    const resS = await APIS.get("section/select").then(res => {
      setSection(res.data.Section)
    })
    const resSE = await APIS.get("session/select").then(res => {
      setSession(res.data.Session)
    })
    const restype = await APIS.get("type/inscription/select").then(restype => {
      setTypeInscription(restype.data.TypeInscription)
    })

    var url = window.location.href
    var array = url.split("=")
    var cin = array[1]

    await APIS.get("annee/select").then(resAnnee => {
      setSelectAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].value)
      setAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].label)
       APIS.post("renseignement/get_by_cin", {
        cin: cin,
        annee_id: resAnnee.data.Annee[0].CurrentAnnee[0].value
      }).then(res => {
         setEtudiantId(res.data.Renseignement.id)
         setPrenomAr(res.data.Renseignement.prenom_ar)
         setPrenomFr(res.data.Renseignement.prenom_fr)
         setNomAr(res.data.Renseignement.nom_ar)
         setNomFr(res.data.Renseignement.nom_fr)
         setPrenomPereAr(res.data.Renseignement.prenonPere_ar)
         setPrenomPereFr(res.data.Renseignement.prenonPere_fr)
         setNomPereAr(res.data.Renseignement.nonPere_ar)
         setNomPereFr(res.data.Renseignement.nonPere_fr)
         setNomMereAr(res.data.Renseignement.nonMere_ar)
         setNomMereFr(res.data.Renseignement.nonMere_fr)
         setPrenomMereAr(res.data.Renseignement.prenonMere_ar)
         setPrenomMereFr(res.data.Renseignement.prenonMere_fr)
         setProfessionMereAr(res.data.Renseignement.professionMere_ar)
         setProfessionMereFr(res.data.Renseignement.professionMere_fr)
         setProfessionPereAr(res.data.Renseignement.professionPere_ar)
         setProfessionPereFr(res.data.Renseignement.professionPere_fr)
         setTelephoneMere(res.data.Renseignement.telephoneMere)
         setTelephonePere(res.data.Renseignement.telephonePere)
         setSexe(res.data.Renseignement.sexe)
         setDate(new Date(res.data.Renseignement.dateNaissance))
         setLieuNaissanceAr(res.data.Renseignement.lieuNaissance_ar)
         setLieuNaissanceFr(res.data.Renseignement.lieuNaissance_fr)
         setSelectGouvernorat(res.data.Renseignement.gouvernorat_fr)
         setSelectNationnalite(res.data.Renseignement.nationalite)
         setCin(res.data.Renseignement.cin)
         setEtatCivil(res.data.Renseignement.etatCivil)
         setSelectTypeInscription(res.data.Renseignement.type_inscription_fr)
         setInscription(res.data.Renseignement.inscription)
         setRueAr(res.data.Renseignement.rue_ar)
         setRueFr(res.data.Renseignement.rue_fr)
         setCodePostal(res.data.Renseignement.codePostal)
         setAccreditationAr(res.data.Renseignement.accreditation_ar)
         setAccreditationFr(res.data.Renseignement.accreditation_fr)
         setTelephone(res.data.Renseignement.telephone)
         setEmail(res.data.Renseignement.email)
         setAnneeBac(res.data.Renseignement.anneBac)
         setSelectSection(res.data.Renseignement.section_fr)
         setSelectMention(res.data.Renseignement.mention_fr)
         setSelectSession(res.data.Renseignement.session_fr)
         setLyceeAr(res.data.Renseignement.lycee_ar)
         setLyceeFr(res.data.Renseignement.lycee_fr)
         setRemarque(res.data.Renseignement.remarque)
         setSelectCycle(res.data.Renseignement.cycle)
         setSelectNiveau(res.data.Renseignement.niveau)
         setSelectSpecialite(res.data.Renseignement.specialite)
         setSelectTypeInstrument(res.data.Renseignement.type_instrument)
         setSelectTypeInstrument1(res.data.Renseignement.type_instrument2)
         setLoading(true)
      })
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    if (cycle_id == 1) {
      const res = await APIS.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await APIS.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }

  const getSpecialite = async event => {
    var niveau_id = event.value
    setSelectNiveau(event)
    setSelectSpecialite("")
    const res = await APIS.post("specialite/get_spec_by_cyc_niv", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.Specialites)
    })
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const edit = async () => {
    if (date != "") {
      let dateNaiss = date
      let month = "" + (dateNaiss.getMonth() + 1)
      let day = "" + dateNaiss.getDate()
      let year = dateNaiss.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //Test si file vide
    var pathFile = ""
    if (file != "") {
      pathFile = file.name
    }
    var nationalite_id = selectNationnalite.label || 0
    if (nomAr == "") {
      toast.error("⛔ Nom arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(nomFr == ""){
      toast.error("⛔ Nom obligatoire !!", {
        containerId: "A",
      })
    }else if(prenomAr ==""){
      toast.error("⛔ Prénom arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(prenomFr ==""){
      toast.error("⛔ Prénom obligatoire !!", {
        containerId: "A",
      })
    }else if(prenomPereAr ==""){
      toast.error("⛔ Prénom père arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(nomPereAr ==""){
      toast.error("⛔ Nom père arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(nomMereAr ==""){
      toast.error("⛔ Nom mère arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(professionPereAr ==""){
      toast.error("⛔ Profession père arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(professionMereAr ==""){
      toast.error("⛔ Profession mère arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(telephoneMere ==""){
      toast.error("⛔ Téléphone mère obligatoire !!", {
        containerId: "A",
      })
    }else if(telephonePere ==""){
      toast.error("⛔ Téléphone père obligatoire !!", {
        containerId: "A",
      })
    }else if(lieuNaissanceAr ==""){
      toast.error("⛔ Lieu Naissance arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(selectGouvernorat ==""){
      toast.error("⛔ Gouvernorat obligatoire !!", {
        containerId: "A",
      })
    }else if(selectNationnalite ==""){
      toast.error("⛔ Nationnalite obligatoire !!", {
        containerId: "A",
      })
    }else if(cin ==""){
      toast.error("⛔ CIN obligatoire !!", {
        containerId: "A",
      })
    }else if(selectTypeInscription ==""){
      toast.error("⛔ Type inscription obligatoire !!", {
        containerId: "A",
      })
    }else if(rueAr ==""){
      toast.error("⛔ Adresse arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(accreditationAr ==""){
      toast.error("⛔ Délégation arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(telephone ==""){
      toast.error("⛔ Téléphone obligatoire !!", {
        containerId: "A",
      })
    }else if(email ==""){
      toast.error("⛔ Email obligatoire !!", {
        containerId: "A",
      })
    }else if(anneeBac ==""){
      toast.error("⛔ Année Bac obligatoire !!", {
        containerId: "A",
      })
    }else if(selectSection ==""){
      toast.error("⛔ Section obligatoire !!", {
        containerId: "A",
      })
    }else if(selectMention ==""){
      toast.error("⛔ Mention obligatoire !!", {
        containerId: "A",
      })
    }else if(selectSession ==""){
      toast.error("⛔ Session obligatoire !!", {
        containerId: "A",
      })
    }else if(lyceeAr ==""){
      toast.error("⛔ L'institut ou l'institution d'origine arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(selectCycle ==""){
      toast.error("⛔ Cycle arabe obligatoire !!", {
        containerId: "A",
      })
    }else if(selectNiveau ==""){
      toast.error("⛔ Niveau obligatoire !!", {
        containerId: "A",
      })
    }else if(selectSpecialite ==""){
      toast.error("⛔ Spécialité obligatoire !!", {
        containerId: "A",
      })
    }else
    if (date != "") {
      setDisbutReg(false)
      APIS.get("annee/select").then(resAnnee => {
        APIS.post("renseignement/edit", {
          id:EtudiantId,
          nom_ar:nomAr,
          nom_fr:nomFr,
          prenom_ar:prenomAr,
          prenom_fr:prenomFr,
          nonPere_ar:nomPereAr,
          nonPere_fr:nomPereFr,
          cin:cin,
          type_inscription_id:selectTypeInscription.value,
          inscription:inscription,
          dateNaissance:convertDate,
          lieuNaissance_ar:lieuNaissanceAr,
          lieuNaissance_fr:lieuNaissanceFr,
          accreditation_ar:accreditationAr,
          accreditation_fr:accreditationFr,
          prenonPere_fr:prenomPereFr,
          prenonPere_ar:prenomPereAr,
          professionPere_ar:professionPereAr,
          professionPere_fr:professionPereFr,
          telephonePere:telephonePere,
          prenonMere_ar:prenomMereAr,
          prenonMere_fr:prenomMereFr,
          nonMere_ar:nomMereAr,
          nonMere_fr:nomMereFr,
          professionMere_ar:professionMereAr,
          professionMere_fr:professionMereFr,
          telephoneMere:telephoneMere,
          etatCivil:etatCivil,
          sexe:sexe,
          rue_ar:rueAr,
          rue_fr:rueFr,
          telephone:telephone,
          email:email,
          nationalite:nationalite_id,
          fichier:pathFile,
          anneBac:anneeBac,
          lycee_ar:lyceeAr,
          lycee_fr:lyceeFr,
          remarque:remarque,
          codePostal:codePostal,
          section_id:selectSection.value,
          session_id:selectSession.value,
          gouvernorat_id:selectGouvernorat.value,
          mention_id:selectMention.value,
          cycle_id:selectCycle.value,
          niveau_id:selectNiveau.value,
          specialite_id:selectSpecialite.value,
          annee_id:resAnnee.data.Annee[0].CurrentAnnee[0].value,
          type_instrument_id:selectTypeInstrument.value,
          type_instrument2_id:selectTypeInstrument1.value
        }).
        then(res => {
          if (res.data.status === 200) {
            if (pathFile != "") {
              const formData = new FormData()
              formData.append("document", file)
              formData.append("cin", cin)
              formData.append("annee_id", resAnnee.data.Annee[0].CurrentAnnee[0].value)
              const resA = APIS.post(
                  "renseignement/import_photo",
                  formData,
                  options
              ).then(resA => {
                setDisplay(1)
                const timer = setTimeout(() => {
                  props.history.push("/DetailIns?cin=" + cin)
                }, 3000)
              })
            } else {
              setDisplay(1)
              const timer = setTimeout(() => {
                props.history.push("/DetailIns?cin=" + cin)
              }, 3000)
            }
          } else if (res.data.errNum === 300) {
            toast.error("⛔ CIN déja existe !!", {
              containerId: "A",
            })
          } else if (res.data.errNum === 301) {
            toast.error("⛔ E-mail déja existe !!", {
              containerId: "A",
            })
          } else if (res.data.errNum === 302) {
            toast.error("⛔ CIN invalide !!", {
              containerId: "A",
            })
          } else if (res.data.errNum === 500) {
            toast.error("⛔ E-mail invalide !!", {
              containerId: "A",
            })
          }
        })
      })

    } else {
      toast.error("⛔ Date de naissance obligatoire !!", {
        containerId: "A",
      })
    }
  }

  return (
      <div>
        {loading ? (
            <div
                style={{
                  backgroundImage: `url(${cresusFond})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "auto",
                  width: "auto",
                }}
            >
              <div style={{ paddingTop: "7%" }}>
                <Container>
                  <Row className="justify-content-center">
                    <Col md={12} lg={12} xl={12}>
                      <Card className="">
                        <div>
                          {" "}
                          <div>
                            <Row>
                              <Col className="justify-content-center pt-4">
                                <h1
                                    style={{
                                      color: "#485ec4", textAlign: "center",
                                    }}
                                >
                                  <p>FICHE DES RENSEIGNEMENTS</p>
                                  <p>A.U {annee}</p>
                                </h1>
                              </Col>
                            </Row>
                          </div>
                          <CardBody className="pt-4">
                            <CardTitle style={{color: "#556ee6"}} className="h4 mb-4 mt-4"> Informations
                              générales : </CardTitle>
                            <AvForm className="form-horizontal">
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Prénom : <span
                                        style={{color: "red", paddingLeft: "5px"}}> * </span>
                                    </Label>
                                    <AvField
                                        value={prenomFr}
                                        name="prenom"
                                        placeholder="Entrer Prénom"
                                        type="text"
                                        errorMessage="* Prénom obligatoire"
                                        className="form-control "
                                        validate={{required: {value: true}}}
                                        onChange={e => setPrenomFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> :
                                      الإسم<span
                                          style={{color: "red", paddingLeft: "5px"}}> *</span>
                                    </Label>
                                    <AvField
                                        value={prenomAr}
                                        name="prenomAr"
                                        placeholder="أدخل الاسم "
                                        type="text"
                                        errorMessage="*الاسم إجباري"
                                        className="form-control rtl"
                                        validate={{required: {value: true}}}
                                        onChange={e => setPrenomAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Nom : <span style={{color: "red", paddingLeft: "5px"}}> *  </span> </Label>
                                    <AvField
                                        value={nomFr}
                                        name="nom"
                                        placeholder="Entrer Nom"
                                        type="text"
                                        errorMessage="* Nom obligatoire"
                                        className="form-control "
                                        validate={{required: {value: true}}}
                                        onChange={e => setNomFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  : اللقب<span style={{color: "red", paddingLeft: "5px"}}> * </span></Label>
                                    <AvField
                                        value={nomAr}
                                        name="nomAr"
                                        placeholder="أدخل اللقب"
                                        type="text"
                                        errorMessage="*اللقب إجباري"
                                        className="form-control rtl"
                                        validate={{required: {value: true}}}
                                        onChange={e => setNomAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Prénom Père : </Label>
                                    <AvField
                                        value={prenomPereFr}
                                        name="prenompere"
                                        placeholder="Entrer Prénom du Père"
                                        type="text"
                                        errorMessage="* Prénom obligatoire"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setPrenomPereFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  : اسم الاب<span style={{ color: "red", paddingLeft: "5px" }}>* </span></Label>
                                    <AvField
                                        value={prenomPereAr}
                                        name="prenomperear"
                                        placeholder="أدخل الاسم الأول للأب"
                                        type="text"
                                        errorMessage="*اسم الأب إجباري"
                                        className="form-control rtl"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setPrenomPereAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Nom Père : </Label>
                                    <AvField
                                        value={nomPereFr}
                                        name="nompere"
                                        placeholder="Entrer Nom du Père"
                                        type="text"
                                        errorMessage="* nom obligatoire"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setNomPereFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : لقب الاب<span style={{ color: "red", paddingLeft: "5px" }} > *</span>
                                    </Label>
                                    <AvField
                                        value={nomPereAr}
                                        name="nomperear"
                                        placeholder="أدخل لقب للأب"
                                        type="text"
                                        errorMessage="*لقب الأب إجباري"
                                        className="form-control rtl"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setNomPereAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Prénom Mère : </Label>
                                    <AvField
                                        value={prenomMereFr}
                                        name="prenommere"
                                        placeholder="Entrer Prénom du Mère"
                                        type="text"
                                        errorMessage="* Prénom obligatoire"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setPrenomMereFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  :  إسم الأم<span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <AvField
                                        value={prenomMereAr}
                                        name="prenommerear"
                                        placeholder="أدخل إسم الأم "
                                        type="text"
                                        errorMessage="إسم الأم إجباري"
                                        className="form-control rtl"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setPrenomMereAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Nom Mère : </Label>
                                    <AvField
                                        value={nomMereFr}
                                        name="nommere"
                                        placeholder="Entrer Nom du Mère"
                                        type="text"
                                        errorMessage="* nom obligatoire"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setNomMereFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  : لقب الأم<span style={{ color: "red", paddingLeft: "5px" }}> *</span>
                                    </Label>
                                    <AvField
                                        value={nomMereAr}
                                        name="nommerear"
                                        placeholder="أدخل لقب الأم"
                                        type="text"
                                        errorMessage="*لقب الأم إجباري"
                                        className="form-control rtl"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setNomMereAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Profession Père : </Label>
                                    <AvField
                                        value={professionPereFr}
                                        name="professionPere"
                                        placeholder="Entrer Profession Mère"
                                        type="text"
                                        errorMessage="* Profession Père obligatoire"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setProfessionPereFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl"> <Label dir="ltr" for="basicpill-firstname-input1">: مهنة الأب<span style={{ color: "red", paddingLeft: "5px" }}>*</span> </Label>
                                    <AvField
                                        value={professionPereAr}
                                        name="professionPereAr"
                                        placeholder="أدخل مهنة الأب "
                                        type="text"
                                        errorMessage=" *  مهنة الأب إجباري"
                                        className="form-control rtl"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setProfessionPereAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Profession Mère : </Label>
                                    <AvField
                                        value={professionMereFr}
                                        name="professionMere"
                                        placeholder="Entrer Profession Mère"
                                        type="text"
                                        errorMessage="* Profession Mère obligatoire"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setProfessionMereFr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  :  مهنة الأم<span style={{ color: "red", paddingLeft: "5px" }}> *</span>
                                    </Label>
                                    <AvField
                                        value={professionMereAr}
                                        name="مهنة الأم "
                                        placeholder="أدخل  مهنة الأم "
                                        type="text"
                                        errorMessage=" * مهنة الأم  إجباري"
                                        className="form-control rtl"
                                        validate={{ required: { value: true } }}
                                        onChange={e => setProfessionMereAr(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Téléphone Père :<span style={{ color: "red", paddingLeft: "5px" }}>*</span></Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <AvField
                                      value={telephonePere}
                                      name="telpere"
                                      placeholder="Entrer téléphone père"
                                      type="number"
                                      errorMessage="* Téléphone obligatoire"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                        min: { value: 8 },
                                      }}
                                      onChange={e => setTelephonePere(e.target.value)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> :  الهاتف الأب<span style={{ color: "red", paddingLeft: "5px" }}> *</span> </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Téléphone Mère : <span style={{ color: "red", paddingLeft: "5px" }}> * </span></Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <AvField
                                      value={telephoneMere}
                                      name="telmere"
                                      placeholder="Entrer téléphone mère"
                                      type="number"
                                      errorMessage="* Téléphone obligatoire"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                        min: { value: 8 },
                                      }}
                                      onChange={e => setTelephoneMere(e.target.value)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> :  الهاتف الأم<span style={{ color: "red", paddingLeft: "5px" }}> * </span>    </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label className="d-block mb-3">Sexe :</Label>
                                    <div className="form-check form-check-inline">
                                      <Input
                                          type="radio"
                                          id="customRadioInline1"
                                          name="customRadioInline1"
                                          className="form-check-input"
                                          checked={sexe == 1}
                                          onClick={() => setSexe(1)}
                                      />
                                      <Label  className="form-check-label" htmlFor="customRadioInline1" > Masculin </Label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                      <Input
                                          type="radio"
                                          id="customRadioInline2"
                                          name="customRadioInline1"
                                          className="form-check-input"
                                          checked={sexe == 2}
                                          onClick={() => setSexe(2)}
                                      />
                                      <Label className="form-check-label" htmlFor="customRadioInline2" > Feminin </Label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Date de naissance : <span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <Col md={12} className="pr-0">
                                      <DatePicker
                                          name="dateNaissance"
                                          className="form-control ddate"
                                          selected={date}
                                          onChange={setDate}
                                          dateFormat="dd/MM/yyyy"
                                          placeholderText="dd/MM/yyyy"
                                          validate={{ required: { value: true } }}
                                          errorMessage="* Date de naissance obligatoire"
                                      />
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Lieu de naissance : </Label>
                                    <AvField
                                        value={lieuNaissanceFr}
                                        name="lieunaissance"
                                        placeholder="Entrer Lieu de naissance"
                                        type="text"
                                        className="form-control"
                                        onChange={e =>setLieuNaissanceFr(e.target.value)}
                                        errorMessage="* Lieu de naissance obligatoire"
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      : مكان الولادة<span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <AvField
                                        value={lieuNaissanceAr}
                                        name="lieunaissanceAr"
                                        placeholder="أدخل مكان الولادة"
                                        type="text"
                                        className="form-control rtl"
                                        onChange={e => setLieuNaissanceAr(e.target.value) }
                                        errorMessage=" * مكان الولادة إجباري "
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Gouvernorat : </Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <Select
                                      value={selectGouvernorat}
                                      options={gouvernorat}
                                      isSearchable={true}
                                      onChange={e => setSelectGouvernorat(e)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">: الولاية</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Nationalité :</Label>
                                  </div>
                                </Col>
                                <Col lg="9" style={{marginBottom:"10px"}}>
                                  <Select
                                      value={selectNationnalite}
                                      options={options}
                                      isSearchable={true}
                                      onChange={e => setSelectNationnalite(e)}
                                  />
                                </Col>
                                <Col lg="1">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : الجنسية</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> N° CIN : <span style={{ color: "red", paddingLeft: "5px" }}> * </span></Label>
                                  </div>
                                </Col>
                                <Col lg="9">
                                  <AvField
                                      value={cin}
                                      name="cin"
                                      placeholder="Entrer identifiant"
                                      type="number"
                                      errorMessage="Min 8 chiffres"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                        min: { value: 8 },
                                      }}
                                      onChange={e => setCin(e.target.value)}
                                  />
                                </Col>
                                <Col lg="1">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  : رقم بطاقة التعريف الوطنية<span style={{ color: "red", paddingLeft: "5px" }}> *</span></Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="1">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Etat Civil : </Label>
                                  </div>
                                </Col>
                                <Col lg="10 center">
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label left mg-r-30" htmlFor="customRadioInline5"> Célibataire </label>
                                    <Input
                                        type="radio"
                                        id="customRadioInline5"
                                        name="customRadioInline5"
                                        className="form-check-input"
                                        checked={etatCivil == 1}
                                        onChange={e => setEtatCivil(1)}
                                    />
                                    <Label className="form-check-label rtl" htmlFor="customRadioInline5"> أعزب</Label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label left mg-r-30" htmlFor="customRadioInline6" > Marié(e) </label>
                                    <Input
                                        type="radio"
                                        id="customRadioInline6"
                                        name="customRadioInline5"
                                        className="form-check-input"
                                        checked={etatCivil == 2}
                                        onChange={e => setEtatCivil(2)}
                                    />
                                    <Label className="form-check-label rtl" htmlFor="customRadioInline6">  متزوج</Label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <label  className="form-check-label left mg-r-30" htmlFor="customRadioInline7"> Autre </label>
                                    <Input
                                        type="radio"
                                        id="customRadioInline7"
                                        name="customRadioInline5"
                                        className="form-check-input"
                                        checked={etatCivil == 3}
                                        onChange={e => setEtatCivil(3)}
                                    />
                                    <Label className="form-check-label rtl" htmlFor="customRadioInline7">  آخر</Label>
                                  </div>
                                </Col>
                                <Col lg="1">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      : الحالة المدنية
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> {"Type d'inscription :"} </Label>
                                  </div>
                                </Col>
                                <Col lg="9" style={{marginBottom:"10px"}}>
                                  <Select
                                      value={selectTypeInscription}
                                      options={typeInscription}
                                      isSearchable={true}
                                      onChange={e => setSelectTypeInscription(e)}
                                  />
                                </Col>
                                <Col lg="1">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      : طريقة الدخول
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="1">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Inscription : </Label>
                                  </div>
                                </Col>
                                <Col lg="10 center">
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label left mg-r-30" htmlFor="customRadioInline9"> Première Inscription</label>
                                    <Input

                                        type="radio"
                                        id="customRadioInline9"
                                        name="customRadioInline9"
                                        className="form-check-input"
                                        checked={inscription == 1}
                                        onChange={e => setInscription(1)}
                                    />
                                    <Label className="form-check-label rtl"  htmlFor="customRadioInline9">  ( أول )</Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label left mg-r-30" htmlFor="customRadioInline10"> Deuxiéme Inscription </label>
                                    <Input
                                        type="radio"
                                        id="customRadioInline10"
                                        name="customRadioInline9"
                                        className="form-check-input"
                                        checked={inscription == 2}
                                        onChange={e => setInscription(2)}
                                    />
                                    <Label className="form-check-label rtl" htmlFor="customRadioInline10">   ( ثاني )</Label>
                                  </div>

                                </Col>
                                <Col lg="1">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">  :  الترسيم</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="1">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Photo : </Label>
                                  </div>
                                </Col>
                                <Col lg="10">
                                  <div className="mb-3">
                                    <Input
                                         onChange={e => addFile(e)}
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                    />
                                  </div>
                                </Col>
                                <Col lg="1">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : الصورة</Label>
                                  </div>
                                </Col>
                              </Row>
                              <CardTitle  style={{ color: "#556ee6" }} className="h4 mb-4 mt-4" >  Adresse : </CardTitle>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">  Adresse :  </Label>
                                    <AvField
                                        value={rueFr}
                                        name="adresse"
                                        placeholder="Entrer Adresse"
                                        type="text"
                                        className="form-control"
                                        onChange={e =>setRueFr(e.target.value)}
                                        errorMessage="* Adresse obligatoire"
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      :  العنوان (بكل دقة)<span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <AvField
                                        value={rueAr}
                                        name="adresseAr"
                                        placeholder="أدخل العنوان (بكل دقة)"
                                        type="text"
                                        className="form-control rtl"
                                        onChange={e => setRueAr(e.target.value) }
                                        errorMessage=" * العنوان (بكل دقة) إجباري "
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Code postal : </Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <Input
                                      value={codePostal}
                                      lg="3"
                                      className="form-control"
                                      placeholder="Entrer code postal"
                                      type="number"
                                      onChange={e => setCodePostal(e.target.value)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      : الترقيم البريدي{" "}
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Délégation :</Label>
                                    <AvField
                                        value={accreditationFr}
                                        name="Délégation"
                                        placeholder="Entrer Délégation"
                                        type="text"
                                        className="form-control"
                                        onChange={e =>setAccreditationFr(e.target.value)}
                                        errorMessage="* Délégation obligatoire"
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      : المعتمدية<span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <AvField
                                        value={accreditationAr}
                                        name="DélégationAr"
                                        placeholder="أدخل المعتمدية"
                                        type="text"
                                        className="form-control rtl"
                                        onChange={e => setAccreditationAr(e.target.value) }
                                        errorMessage=" * المعتمدية إجباري "
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Téléphone :<span style={{ color: "red", paddingLeft: "5px" }}> *</span></Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <AvField
                                      value={telephone}
                                      name="tel"
                                      placeholder="Entrer téléphone"
                                      type="number"
                                      errorMessage="* Téléphone obligatoire"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                        min: { value: 8 },
                                      }}
                                      onChange={e => setTelephone(e.target.value)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">: الهاتف<span style={{ color: "red", paddingLeft: "5px" }}> *</span> </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">E-mail : <span style={{ color: "red", paddingLeft: "5px" }}> *</span></Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <AvField
                                      value={email}
                                      name="email"
                                      placeholder="Entrer un email valide"
                                      type="email"
                                      errorMessage="Email invalide"
                                      onChange={e => setEmail(e.target.value)}
                                      validate={{
                                        required: { value: true },
                                        email: { value: true },
                                      }}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : البريد الإلكتروني<span style={{ color: "red", paddingLeft: "5px" }}> *</span></Label>
                                  </div>
                                </Col>
                              </Row>
                              <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4" > Baccalauréat  </CardTitle>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Année du bac : </Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <Input value={anneeBac}
                                      lg="3"
                                      className="form-control"
                                      placeholder="Entrer année du bac"
                                      type="number"
                                      onChange={e => setAnneeBac(e.target.value)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : سنة الباكالوريا</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Section :
                                    </Label>
                                  </div>
                                </Col>
                                <Col lg="8" style={{marginBottom:"10px"}}>
                                  <Select
                                      value={selectSection}
                                      options={section}
                                      isSearchable={true}
                                      onChange={e => setSelectSection(e)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      : الشعبة
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Mention :</Label>
                                  </div>
                                </Col>
                                <Col lg="8"  style={{marginBottom:"10px"}}>
                                  <Select
                                      value={selectMention}
                                      options={mention}
                                      isSearchable={true}
                                      onChange={e => setSelectMention(e)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : ملاحظة</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="2">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Session :</Label>
                                  </div>
                                </Col>
                                <Col lg="8"  style={{marginBottom:"10px"}}>
                                  <Select
                                      value={selectSession}
                                      options={session}
                                      isSearchable={true}
                                      onChange={e => setSelectSession(e)}
                                  />
                                </Col>
                                <Col lg="2">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1"> : الدورة</Label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      {"L'institut ou l'institution d'origine :"}<span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <AvField
                                        value={lyceeFr}
                                        name="lycee"
                                        placeholder="Entrer lycee"
                                        type="text"
                                        className="form-control"
                                        onChange={e =>setLyceeFr(e.target.value)}
                                        errorMessage="* Délégation obligatoire"
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div dir="rtl" className="mb-3 rtl">
                                    <Label dir="ltr" for="basicpill-firstname-input1">
                                      :  المعهد أو المؤسسة الأصلية<span style={{ color: "red", paddingLeft: "5px" }}> * </span>
                                    </Label>
                                    <AvField
                                        value={lyceeAr}
                                        name="lyceeAr"
                                        placeholder="أدخل  المعهد أو المؤسسة الأصلية"
                                        type="text"
                                        className="form-control rtl"
                                        onChange={e => setLyceeAr(e.target.value) }
                                        errorMessage=" *  المعهد أو المؤسسة الأصلية إجباري "
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      {"Remarque :"}
                                    </Label>
                                    <AvField
                                        value={remarque}
                                        name="remarque"
                                        placeholder="Entrer remarque"
                                        type="text"
                                        className="form-control"
                                        onChange={e =>setRemarque(e.target.value)}
                                        errorMessage="* Remarque obligatoire"
                                        validate={{ required: { value: true } }}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4"> Diplôme </CardTitle>
                              <Row style={{padding:" 0px 0px 36px 0px"}}>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Cycle : <span  style={{ color: "red", paddingLeft: "5px" }} > * </span> </Label>
                                    <Select
                                        value={selectCycle}
                                        options={cycle}
                                        isSearchable={true}
                                        onChange={e => getNiveau(e)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Niveau :<span style={{ color: "red", paddingLeft: "5px" }}> * </span></Label>
                                    <Select
                                        value={selectNiveau}
                                        options={niveau}
                                        isSearchable={true}
                                        onChange={e => getSpecialite(e)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1"> Spécialité :<span style={{ color: "red", paddingLeft: "5px" }}>*</span> </Label>
                                    <Select
                                        value={selectSpecialite}
                                        options={specialite}
                                        isSearchable={true}
                                        onChange={e => setSelectSpecialite(e)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row style={{padding:" 0px 0px 36px 0px"}}              >
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">{"Type d'instrument 1 : ( Optionnel )"}</Label>
                                    <Select
                                        options={typeInstrument}
                                        isSearchable={true}
                                        value={selectTypeInstrument}
                                        onChange={e => setSelectTypeInstrument(e)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">{"Type d'instrument 2 : ( Spécialité )"}</Label>
                                    <Select
                                        options={typeInstrument1}
                                        isSearchable={true}
                                        value={selectTypeInstrument1}
                                        onChange={e => setSelectTypeInstrument1(e)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div style={{ display: "flex",justifyContent: "center", }}>
                                <Col lg="12" style={{display: "flex", justifyContent: "center", }}>
                                  <Button
                                      type="button"
                                      className="btn btn-warning mb-2 me-2"
                                      onClick={() => props.history.push("/login")}
                                  >
                                    Annuler
                                  </Button>
                                  <Button
                                      id="Confirmer"
                                      name="Confirmer"
                                      type="button"
                                      color="primary"
                                      className="btn btn-primary mb-2 me-2"
                                      disabled={!disbutReg}
                                      onClick={edit}
                                  >
                                    Confirmer
                                  </Button>
                                </Col>
                              </div>
                            </AvForm>
                          </CardBody>
                          <div className="mt-1 text-center">
                            <p>
                              © {new Date().getFullYear()} Cresus. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger"/> by{" "}
                              <a href="https://cresus.pro/">cresus.pro</a>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              <ToastContainer
                  transition={Flip}
                  enableMultiContainer
                  containerId={"A"}
                  position={toast.POSITION.TOP_RIGHT}
                  autoClose={2500}
              />
            </div>
        ) : (
            <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-50px",
                  marginLeft: "-50px",
                }}
            >
              <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
            </div>
        )}
      </div>
  )
}

export default withRouter(EditIns)

EditIns.propTypes = {
  history: PropTypes.object,
}
