import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Spinner } from "reactstrap"
import API from "../../apis"
import ISGGB from "../../assets/images/ismsf.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const EmploiExamen = props => {
  if (localStorage.getItem("userAuthEtudiant")) {
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant
    var annee_id = userAuthEtudiant.user.annee_id
    var nameEtud = userAuthEtudiant.user.name
  }
  const [loading, setLoading] = useState(false)
  const [detailExam, setDetailExam] = useState([])
  const [specialite, setSpecialite] = useState("")
  const [anneeActuel, setAnneeActuel] = useState("");
  useEffect(async () => {
    const res = API.post("examen/final/calendrier_examen", {
      etudiant_id: etudiant_id,
      semestre_id: props.semestre_value,
      session_id: 1,
      annee_id
    }).then(res => {
      setDetailExam(res.data.Surveillance.surveillance)
      setSpecialite(res.data.Surveillance.specialite)
      setAnneeActuel(res.data.Surveillance.annee)
      setLoading(true)
    })
  }, [props.semestre_value])

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" "}
                  de la Recherche Scientifique
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Sfax
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"Institut Supérieur de Musique de Sfax"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة ﺻﻔﺎﻗﺲ
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {"اﻟﻤﻌﻬﺪ اﻟﻌﺎﻟﻲ ﻟﻠﻤﻮﺳﻴﻘﻰ ﺑﺼﻔﺎﻗﺲ "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div className="row">
              <div className="col-md-4">
                <a
                  href={
                    "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_calendrier_etudiant/"+etudiant_id+"/"+1+"/"+props.semestre_value +"/"+annee_id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn btn-primary">
                    <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                    Calendrier
                  </button>
                </a>
              </div>
              <div className="col-md-4">
                <div
                  style={{
                    display: "flex",
                    fontSize: "15px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px", fontWeight: "bold" }}>
                    Calendrier des examens
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "15px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Session :</span>
                  <span style={{ fontWeight: "bold" }}>Principale</span>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "13px",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ paddingRight: "4px" }}>
                      Année Universitaire:{" "}
                    </span>
                    <span style={{ fontWeight: "bold" }}>{anneeActuel}</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Semestre:</span>
                  <span style={{ fontWeight: "bold" }}>
                    {props.semestre_label}
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "end",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Spécialité :</span>
                  <span style={{ fontWeight: "bold" }}>{specialite}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "end",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Etudiant :</span>
                  <span style={{ fontWeight: "bold" }}>{nameEtud}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th className="div-center">Journée</th>
                    <th className="div-center">Date</th>
                    <th className="div-center">Horaire</th>
                    <th className="div-center">Matiere</th>
                    <th className="div-center">Salle</th>
                  </tr>
                </thead>
                {detailExam.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td
                        className="div-center"
                        style={{
                          verticalAlign: "middle",
                        }}
                        rowSpan={el.detail.length + 1}
                      >
                        {el.jour}
                      </td>
                      <td
                        className="div-center"
                        style={{
                          verticalAlign: "middle",
                        }}
                        rowSpan={el.detail.length + 1}
                      >
                        {el.date}
                      </td>
                    </tr>
                    {el.detail.map((elem, indice) => (
                      <tr key={indice}>
                        <td className="div-center">{elem.quart_examen_id}</td>
                        <td className="div-center">{elem.matiere}</td>
                        <td className="div-center">{elem.salle}</td>
                      </tr>
                    ))}
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default EmploiExamen
EmploiExamen.propTypes = {
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  semestre_label: PropTypes.object,
}
