import React, { useEffect, useState } from "react"
import { Button, Card, Col, Container, Row } from "reactstrap"
import APIS from "../../apis"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
import logo from "../../assets/images/ismsf.png"
import user1 from "../../assets/images/users/userImage.png"
import { Grid } from "react-loader-spinner"

const DetailIns = props => {
  const [nomPrenom, setNomPrenom] = useState("")
  const [nomPrenomAr, setNomPrenomAr] = useState("")
  const [prenomPere, setPrenomPere] = useState("")
  const [prenomPereAr, setPrenomPereAr] = useState("")
  const [gender, setGender] = useState(1)
  const [date, setDate] = useState("")
  const [gouvernoratNaiss, setGouvernoratNaiss] = useState("")
  const [gouvernoratNaissAr, setGouvernoratNaissAr] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [numero, setNumero] = useState("")
  const [etatCivil, setEtatCivil] = useState(1)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [delegation, setDelegation] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState("")
  const [mention, setMention] = useState("")
  const [session, setSession] = useState("")
  const [payObtention, setPayObtention] = useState("")
  const [premiereIns, setPremiereIns] = useState("")
  const [selectCycle, setSelectCycle] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [loading, setLoading] = useState(false)
  const [etudiantId, setEtudiantId] = useState("")
  const [etat, setEtat] = useState("")
  const [CIN, setCIN] = useState("")
  const [moyen, setMoyen] = useState("");
  const [selectAnnee, setSelectAnnee] = useState("");
  const [nomPrenomMere, setNomPrenomMere] = useState("");
  const [nomPrenomMereAr, setNomPrenomMereAr] = useState("");
  const [annee, setAnnee] = useState("")
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var cin = array[1]
    setCIN(cin)
    APIS.get("annee/select").then(resAnnee => {
      setSelectAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].value)
      setAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].label)
       APIS.post("renseignement/get_by_cin", {
        cin: cin,
        annee_id: resAnnee.data.Annee[0].CurrentAnnee[0].value
      }).then(res => {
        if (res.data.status === 200) {
          const resC = APIS.post("check_profile_image", {
            type: "etudiants",
            id: res.data.Renseignement.id,
            annee_id:resAnnee.data.Annee[0].CurrentAnnee[0].value
          }).then(resC => {
            setEtat(resC.data.error)
          })
        }
        setEtudiantId(res.data.Renseignement.id)
        setNomPrenomMere(res.data.Renseignement.prenonMere_fr + ' ' + res.data.Renseignement.nonMere_fr)
        setNomPrenomMereAr(res.data.Renseignement.prenonMere_fr + ' ' + res.data.Renseignement.nonMere_fr)
        setNomPrenom(res.data.Renseignement.prenom_fr + ' ' + res.data.Renseignement.nom_fr)
        setNomPrenomAr(res.data.Renseignement.prenom_ar + ' ' + res.data.Renseignement.nom_ar)
        setPrenomPere(res.data.Renseignement.prenonPere_fr+' '+res.data.Renseignement.nonPere_fr)
        setPrenomPereAr(res.data.Renseignement.prenonPere_ar + ' '+res.data.Renseignement.nonPere_fr)
        setGender(res.data.Renseignement.sexe)
        setDate(res.data.Renseignement.dateNaissance)
        setGouvernoratNaiss(res.data.Renseignement.lieuNaissance_fr)
        setGouvernoratNaissAr(res.data.Renseignement.lieuNaissance_ar)
        setSelectNationnalite(res.data.Renseignement.nationalite.label)
        setNumero(cin)
        setEtatCivil(res.data.Renseignement.etatCivil)
        setRue(res.data.Renseignement.rue)
        setCodePostal(res.data.Renseignement.codePostal)
        setGouvernorat(res.data.Renseignement.gouvernorat_fr.label)
        setDelegation(res.data.Renseignement.delegation)
        setTel(res.data.Renseignement.telephone)
        setEmail(res.data.Renseignement.email)
        setSection(res.data.Renseignement.section_fr.label)
        setMention(res.data.Renseignement.mention_fr.label)
        setSession(res.data.Renseignement.session_fr.label)
        setAnneeBac(res.data.Renseignement.anneBac)
        setPayObtention(res.data.Renseignement.paysObtention)
        setPremiereIns(res.data.Renseignement.premereInscription)
        setSelectNiveau(res.data.Renseignement.niveau.label)
        setSelectCycle(res.data.Renseignement.cycle.label)
        setSelectSpecialite(res.data.Renseignement.specialite.label)
        setMoyen(res.data.Renseignement.moyen)
        setLoading(true)
      })
    })
  }, [])

  const impr = () => {
    window.open(
        "https://ismsf.etudiant.cresus-institut.ovh/ImpressionInscription?cin=" + numero
        // "http://localhost:3001/ImpressionInscription?cin=" + numero
    )
  }

  return (
      <div>
        {loading ? (
            <div
                style={{
                  backgroundImage: `url(${cresusFond})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "auto",
                  width: "auto",
                }}
            >
              <div style={{ paddingTop: "7%" }}>
                <Container>
                  <Row className="justify-content-center">
                    <Col md={12} lg={12} xl={12}>
                      <Card className="overflow-hidden">
                        <div>
                          {" "}
                          <div>
                            <Row>
                              <Col className="justify-content-center pt-4">
                                <h1
                                    style={{
                                      color: "#485ec4",
                                      textAlign: "center",
                                    }}
                                >
                                  <p>FICHE DES RENSEIGNEMENTS</p>
                                  <p>A.U {annee}</p>
                                </h1>
                              </Col>
                            </Row>
                          </div>
                          <table className="table borderless">
                            <tbody>
                            <tr>
                              <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                              >
                                <img src={logo} width={100} />
                              </td>
                              <td colSpan={2} />
                              <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  nowrap
                              >
                                <h4
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "12px",
                                      fontFamily: '"Tahoma"',
                                      direction: "rtl",
                                    }}
                                >
                                  المعهد العالي للموسيقى بصفاقس
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4} valign="middel">
                                <div
                                    style={{
                                      height: "1px",
                                      width: "100%",
                                      borderBottom: "solid 1px #eee",
                                      marginBottom: "15px",
                                    }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                  colSpan={4}
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                              >
                                <center>
                                  <h4
                                      style={{
                                        padding: "0px",
                                        margin: "0px",
                                        fontSize: "17px",
                                        fontFamily: '"Tahoma"',
                                      }}
                                  >
                                    Fiche des Renseignements - بطاقة بيانات
                                  </h4>
                                </center>
                                <br />
                                <center>
                                  <h4
                                      style={{
                                        padding: "0px",
                                        margin: "0px",
                                        fontSize: "17px",
                                        fontFamily: '"Tahoma"',
                                      }}
                                  >
                                    Années universitaire {annee}
                                  </h4>
                                </center>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div style={{ width: "130px" }}>
                                  {etat != 0 ? (
                                      <img
                                          style={{ width: "100%" }}
                                          src={
                                              "https://ismsf.scolarite.backcresus-institut.ovh/api/assetsmanager/renseignement/" +etudiantId+"/"+selectAnnee
                                          }
                                      />
                                  ) : (
                                      <img style={{ width: "100%" }} src={user1} />
                                  )}
                                </div>
                                <br />
                                <br />
                              </td>
                              <td />
                              <td dir="rtl" />
                              <td dir="rtl" />
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <h4
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "14px",
                                      fontFamily: '"Tahoma"',
                                      color: "#0e8395",
                                      fontWeight: 600,
                                    }}
                                >
                                  Informations générales
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Nom et Prénom</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {nomPrenom}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                {nomPrenomAr}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                <b>الإسم و اللقب</b>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Prénom Père</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {prenomPere}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                {prenomPereAr}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                <b>الإسم و اللقب الاب</b>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Nom et Prénom Mère</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {nomPrenomMere}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                {nomPrenomMereAr}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                <b>الإسم و اللقب الأم</b>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Sexe</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {gender == 1 ? "Masculin" : "Féminin"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Date de naissance</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {date}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Gouvernorat de naissance</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {gouvernoratNaiss}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                {gouvernoratNaissAr}
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                                  dir="rtl"
                              >
                                <b>الولاية</b>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Nationalité</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {selectNationnalite}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>N° CIN ou N° Passeport pour les étrangers</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {numero}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Etat Civil</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {etatCivil == 1
                                    ? "Célibataire"
                                    : etatCivil == 2
                                        ? "Marié(e)"
                                        : "Divorcé(e)"}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <br />
                                <br />
                                <h4
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "14px",
                                      fontFamily: '"Tahoma"',
                                      color: "#0e8395",
                                      fontWeight: 600,
                                    }}
                                >
                                  Adresse
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Rue</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {rue}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Code postal</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {" "}
                                {codePostal}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Gouvernorat</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {" "}
                                {gouvernorat}{" "}
                              </td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <td*/}
                            {/*      style={{*/}
                            {/*        fontSize: "12px",*/}
                            {/*        fontFamily: '"Tahoma"',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*    <b>Délégation</b>*/}
                            {/*  </td>*/}
                            {/*  <td*/}
                            {/*      style={{*/}
                            {/*        fontSize: "12px",*/}
                            {/*        fontFamily: '"Tahoma"',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*    {delegation}*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Téléphone</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {tel}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>E-mail</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {email}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <br />
                                <br />
                                <h4
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "14px",
                                      fontFamily: '"Tahoma"',
                                      color: "#0e8395",
                                      fontWeight: 600,
                                    }}
                                >
                                  Baccalauréat
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Année du bac</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {anneeBac}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Section</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {section}
                              </td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <td*/}
                            {/*      style={{*/}
                            {/*        fontSize: "12px",*/}
                            {/*        fontFamily: '"Tahoma"',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*    <b>Moyen Bac</b>*/}
                            {/*  </td>*/}
                            {/*  <td*/}
                            {/*      style={{*/}
                            {/*        fontSize: "12px",*/}
                            {/*        fontFamily: '"Tahoma"',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*    {" "}*/}
                            {/*    {moyen}{" "}*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Mention</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {mention}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Session</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {" "}
                                {session}{" "}
                              </td>
                            </tr>

                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>{"Pays d'obtention"}</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {payObtention}{" "}
                              </td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <td*/}
                            {/*      style={{*/}
                            {/*        fontSize: "12px",*/}
                            {/*        fontFamily: '"Tahoma"',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*    <b>{"1ère inscription à L'établissement"}</b>*/}
                            {/*  </td>*/}
                            {/*  <td*/}
                            {/*      style={{*/}
                            {/*        fontSize: "12px",*/}
                            {/*        fontFamily: '"Tahoma"',*/}
                            {/*      }}*/}
                            {/*  >*/}
                            {/*    {premiereIns}{" "}*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            <tr>
                              <td colSpan={4}>
                                <br />
                                <br />
                                <h4
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "14px",
                                      fontFamily: '"Tahoma"',
                                      color: "#0e8395",
                                      fontWeight: 600,
                                    }}
                                >
                                  Diplôme
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Diplôme</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {selectCycle}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Niveau</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {" "}
                                {selectNiveau}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                <b>Spécialité</b>
                              </td>
                              <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: '"Tahoma"',
                                  }}
                              >
                                {" "}
                                {selectSpecialite}{" "}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div
                              style={{ display: "flex", justifyContent: "center" }}
                          >
                            {" "}
                            <Col lg="6">
                              <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  className="text-center mt-4"
                              >
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning  mb-2 me-2"
                                    onClick={impr}
                                >
                                  Imprimer
                                </Button>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                  className="text-center mt-4"
                              >
                                <button
                                    onClick={() =>
                                        props.history.push("/EditIns?cin=" + CIN)
                                    }
                                    type="submit"
                                    className="btn btn-primary "
                                >
                                  Modifier
                                </button>
                              </div>
                            </Col>
                          </div>
                          <div className="mt-1 text-center">
                            <p>
                              © {new Date().getFullYear()} Cresus. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger" /> by{" "}
                              <a href="https://cresus.pro/">cresus.pro</a>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
        ) : (
            <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-50px",
                  marginLeft: "-50px",
                }}
            >
              <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
            </div>
        )}
      </div>
  )
}

export default withRouter(DetailIns)

DetailIns.propTypes = {
  history: PropTypes.object,
}
