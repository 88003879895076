import React, { useEffect, useState } from "react"
import { Grid } from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import axios from "axios"

const Login = props => {
  const [api_password, setApi_paasword] = useState("FGguKc8tTYKd6tLT")
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    setLoading(false)
    var url = window.location.href
    var array = url.split("=")
    var email = array[1]
    var email = array[1].split("&")
    email = email[0]
    var password = array[1]
    password = array[2].split("&")
    password = password[0]
    // etudiant
    const res = await axios.post(
      "https://etudiant.backcresus-institut.ovh/api/login",
      {
        password: data.password,
        email: data.email,
        API_PASSWORD: api_password,
        anneeScolaire,
      }
    )
    if (res.data.status === 200) {
      if (res.data.user.email != null) {
        localStorage.setItem("I18N_LANGUAGE", "fr")
        localStorage.setItem("userAuthEtudiant", JSON.stringify(res.data))
        localStorage.setItem(
          "userAuthScolarite",
          JSON.stringify(res.data.user.scolarite_token)
        )
        props.history.push("/dashboard")
        window.location.reload(false)
      } else {
        localStorage.setItem("I18N_LANGUAGE", "fr")
        localStorage.setItem("userAuthEtudiant", JSON.stringify(res.data))
        localStorage.setItem(
          "userAuthScolarite",
          JSON.stringify(res.data.user.scolarite_token)
        )
        props.history.push("/ProfileEtudiant")
        window.location.reload(false)
      }
      window.location.reload(false)
    } else {
      setLoading(true)
      toast.error("⛔ Veuillez vérifier votre CIN et mot de passe !", {
        containerId: "A",
      })
    }
  })

  return (
    <div>
      {loading ? (
        <div></div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
          }}
        >
          <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
        </div>
      )}
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
