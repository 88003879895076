import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {Row, Form, Col, Button, Label, Input} from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import AttestationPresence from "./AttestationPresence"
import {Slide, toast, ToastContainer} from "react-toastify"
import API from "../../apis"
import {AvForm} from "availity-reactstrap-validation"
import Select from "react-select"
import APIS from "../../apis"

const AddDemande = props =>{
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  var annee_id = userAuthEtudiant.user.annee_id
  const [disbutReg, setDisbutReg] = useState(true)
  const [raison, setRaison] = useState("")
  const [langue, setLangue] = useState([
    { value: 1, label: "Français" },
    { value: 2, label: "Arabe" },
  ])
  const [selectLangue, setSelectLangue] = useState("")
  const [attestation, setAttestation] = useState([])
  const [selectAttestation, setSelectAttestation] = useState("")

  const save = async () => {
    if(selectLangue == ""){
        setDisbutReg(true)
        toast.error("⛔ Langue de demande est obligatoire !", {
          containerId: "A",
        })
    }else if(selectAttestation == ""){
        setDisbutReg(true)
        toast.error("⛔ Type de attestation est obligatoire !", {
          containerId: "A",
        })
    }else{
      setDisbutReg(false)
      const res = await APIS.post("demande/etudiant/add", {
        raison: raison,
        etudiant_id: etudiant_id,
        langue: selectLangue.value,
        etudiant_attestation_id: selectAttestation.value,
        annee_id
      }).then(res => {
        props.setSection(1)
      }).catch(() => {
        setDisbutReg(true)
        toast.error("⛔  Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
    }
  }
  useEffect(async () => {

  })
  const getAttesttation = async event => {
    var langue_id = event.value
    setSelectLangue(event)
    const res = await API.post("demande/etudiant/select/demande/disponible",{langue:langue_id}).then(res => {
      setAttestation(res.data.EtudiantAttestation)
    })
  }
  return (
      <React.Fragment>
        <Row>
          <AvForm>
            <Form>
             <Row>
               <Col md={6}>
                 <div className="mb-3">
                   <Label for="basicpill-firstname-input1">
                     {props.t("Langue")}
                   </Label>
                   <Select
                       options={langue}
                       type="text"
                       onChange={e => getAttesttation(e)}
                   />
                 </div>
               </Col>
               <Col md={6}>
                 <div className="mb-3">
                   <Label for="basicpill-firstname-input1">
                     {props.t("Type de attestation")}
                   </Label>
                   <Select
                       options={attestation}
                       type="text"
                       onChange={e => setSelectAttestation(e)}
                   />
                 </div>
               </Col>
             </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      {props.t("Raison")}
                    </Label>
                    <Input
                        type="textarea"
                        className="form-control"
                        placeholder={props.t("Raison")}
                        onChange={e => setRaison(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                >
                  <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={props.back}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                >
                  <button
                      type="button"
                      className="btn btn-primary "
                      onClick={save}
                      disabled={!disbutReg}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </Row>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddDemande))
AddDemande.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
