import React, { useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Container, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { MetaTags } from "react-meta-tags"
import EmploiExamen from "./EmploiExamen"
import EmploiExamenControle from "./EmploiExamenControle";

const CalendrierExamenControle = props => {
  const [section, setSection] = useState(1)


  const renderBlock = section => {

      if (section == 1) {
        return (
          <EmploiExamenControle />
        )

    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Emploi</title>
        </MetaTags>
        <Container fluid>
          <Nav tabs>
            <NavItem>
              <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
              >
                Contrôle
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody>{renderBlock(section)}</CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CalendrierExamenControle
CalendrierExamenControle.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
