import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../apis"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";

const EditDemandeStage = props => {
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  var cycle_id = userAuthEtudiant.user.cycle_id
  var niveau_id = userAuthEtudiant.user.niveau_id
  const [disbutReg, setDisbutReg] = useState(true)
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [titre, setTitre] = useState("");
  const [dureeStage, setDureeStage] = useState("");
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("");
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]

    if(cycle_id == 2 && niveau_id == 2){
      const res = await API.get("enseignant/get_ens_master").then(res => {
        setEnseignant(res.data.enseignant)
      })
    }else if( cycle_id == 1 && niveau_id == 3){
      const res = await API.get("enseignant/select").then(res => {
        setEnseignant(res.data.select_enseignant)
      })
    }
    await API.post("etudiant/get_detail_stage", { id }).then(res => {
      setLoading(true)
      setId(res.data.detail_stage.id)
      setSelectEnseignant(res.data.detail_stage.enseignant)
      setTitre(res.data.detail_stage.titre)
      setDureeStage(res.data.detail_stage.durée_du_stage)
    })
  }, [])
  const save = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if(titre == ""){
      setDisbutReg(true)
      toast.error("⛔ Titre est obligatoire !", {
        containerId: "A",
      })
    }else if(dureeStage==""){
      setDisbutReg(true)
      toast.error("⛔ Durée de stage est obligatoire !", {
        containerId: "A",
      })
    }else if (selectEnseignant == ""){
      setDisbutReg(true)
      toast.error("⛔ Enseignant est obligatoire !", {
        containerId: "A",
      })
    }else{
      if(file == null){
        var file_update= ""
      }else{
        var file_update=file
      }
      const formData = new FormData();
      formData.append('id', id);
      formData.append('enseignant_id', selectEnseignant.value);
      formData.append('titre', titre);
      formData.append('durée_du_stage', dureeStage);
      formData.append('etudiant_id', etudiant_id);
      formData.append('fichier_stage', file_update);
      const res = await API.post("etudiant/update_stage", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }}).then(res => {
        props.history.push("/DemandeStage")
      })
    }


  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }
  return (
      <React.Fragment>
        <div className="page-content">

          {loading ? (
              <Container fluid>
                <Card>
                  <CardBody>
                    <Form>
                      <Row>
                        <AvForm>
                          <Form>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    {props.t("Titre du sujet")} :
                                  </Label>
                                  <Input
                                      lg="3"
                                      type="text"
                                      className="form-control"
                                      placeholder={props.t("Titre du sujet")}
                                      onChange={e => setTitre(e.target.value)}
                                      value={titre}
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    {props.t("Durée du sujet")} :
                                  </Label>
                                  <Input
                                      value={dureeStage}
                                      lg="3"
                                      type="text"
                                      className="form-control"
                                      placeholder={props.t("Durée du sujet")}
                                      onChange={e => setDureeStage(e.target.value)}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    {props.t("Enseignant")}
                                  </Label>
                                  <Select
                                      options={enseignant}
                                      isSearchable={true}
                                      value={selectEnseignant}
                                      onChange={e => setSelectEnseignant(e)}
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">Fichier de stage :</Label>
                                  <Input
                                      onChange={e => addFile(e)}
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                      accept=".pdf, .doc, .docx , .DOC , .DOCX"

                                  />
                                </div>
                              </Col>
                            </Row>

                          </Form>
                          <div style={{display: "flex", justifyContent: "center"}}>
                            {" "}
                            <Col lg="6">
                              <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  className="text-center mt-4"
                              >
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning  mb-2 me-2"
                                    onClick={() => props.history.push("/DemandeStage")}
                                >
                                  {props.t("Annuler")}
                                </Button>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                  className="text-center mt-4"
                              >
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={save}
                                    disabled={!disbutReg}
                                >
                                  {props.t("Confirmer")}
                                </button>
                              </div>
                            </Col>
                          </div>
                        </AvForm>
                      </Row>
                    </Form>
                    <ToastContainer
                        transition={Slide}
                        enableMultiContainer
                        containerId={"A"}
                        position={toast.POSITION.TOP_RIGHT}
                        autoClose={2500}
                    />
                  </CardBody>
                </Card>
              </Container>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {props.t("Chargement en cours...")}
                </h4>
              </div>
          )}
        </div>

      </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditDemandeStage))
EditDemandeStage.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
