import React, { useState} from "react"
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import DataTableDemande from "./DataTableDemande"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import AddDemande from "./AddDemande"
const Demands = props => {
  const [section, setSection] = useState(1)
  const back = () => {
    setSection(1)
  }
  const renderBlock = section => {
    if (section === 1) {
      return <DataTableDemande />
    } else if (section === 2) {
      return <AddDemande setSection={setSection} back={back} />
    }
  }
   return (
     <React.Fragment>
        <div>
          <MetaTags>
            <title>{props.t("Demande")} | Cresus </title>
          </MetaTags>
          <Container fluid>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === 1,
                      })}
                      onClick={() => {
                        setSection(1)
                      }}
                  >
                    {props.t("Demande")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === 2,
                      })}
                      onClick={() => {
                        setSection(2)
                      }}
                  >
                    {props.t("Ajouter")} {props.t("Demande")}
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>{renderBlock(section)}</CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </React.Fragment>
   )
  }

Demands.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(Demands))
