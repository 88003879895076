import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Spinner } from "reactstrap"
import API from "../../apis"
import ISGGB from "../../assets/images/ismsf.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const EmploiDS2 = props => {
  if (localStorage.getItem("userAuthEtudiant")) {
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant
    var annee_id = userAuthEtudiant.user.annee_id
    var nameEtud = userAuthEtudiant.user.name
  }
  const [loading, setLoading] = useState(false)
  const [detailExam, setDetailExam] = useState([])
  const [specialite, setSpecialite] = useState("")

  useEffect(async () => {
    const res = API.post("avis/examen/get_avis_controle", {
      etudiant_id: etudiant_id,
      semestre_id: props.semestre_value,
      annee_id
    }).then(res => {
      setDetailExam(res.data.test)
      // setSpecialite(res.data.Surveillance.specialite)
      setLoading(true)
    })
  }, [props.semestre_value])

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" "}
                  de la Recherche Scientifique
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Sfax
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"Institut Supérieur de Musique de Sfax"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة ﺻﻔﺎﻗﺲ
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {"اﻟﻤﻌﻬﺪ اﻟﻌﺎﻟﻲ ﻟﻠﻤﻮﺳﻴﻘﻰ ﺑﺼﻔﺎﻗﺲ "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div className="row">
              <div  className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    fontSize: "15px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px", fontWeight: "bold" }}>
                    Calendrier de devoir de contenu
                  </span>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "13px",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ paddingRight: "4px" }}>
                      Année Universitaire:{" "}
                    </span>
                    <span style={{ fontWeight: "bold" }}>2022/2023</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Semestre:</span>
                  <span style={{ fontWeight: "bold" }}>{props.semestre_label}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th className="div-center">Journée</th>
                    <th className="div-center">Date</th>
                    <th className="div-center">Horaire</th>
                    <th className="div-center">Matiere</th>
                    <th className="div-center">Salle</th>
                  </tr>
                </thead>
                {detailExam.map((el, index) => (
                  <tbody key={index}>
                      <tr>
                        <td className="div-center">{el.jour}</td>
                        <td className="div-center">{el.date}</td>
                        <td className="div-center">{el.quart.label}</td>
                        <td className="div-center">{el.matiere.name_fr}</td>
                        <td className="div-center">{el.salle.name}</td>
                      </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default EmploiDS2
EmploiDS2.propTypes = {
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  semestre_label: PropTypes.object,
}
