import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import API from "../../apis";
const SidebarContent = props => {
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var niveau_id = userAuthEtudiant.user.niveau_id
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  var annee_id = userAuthEtudiant.user.annee_id
  var cycle_id = userAuthEtudiant.user.cycle_id
  const ref = useRef()
  //
  var lng = localStorage.getItem("I18N_LANGUAGE")
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const historique = () => {
    API.post("etudiant/store_hist_empl_etu", {
      etudiant_id: etudiant_id,
      annee_id:annee_id
    })
  };
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className={lng == "ar" ? "sidebar-menu-ar" : ""}>
          <ul
            className={
              lng == "ar"
                ? "metismenu-ar list-unstyled"
                : "metismenu list-unstyled"
            }
            id="side-menu"
          >
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Actualités")}</span>
              </Link>
            </li>
            <li onClick={historique}>
              <Link to="/Emploi" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Emploi du temps")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="fas fa-calendar-alt"></i>
                <span>{props.t("Calendrier examen")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/CalendrierExamen">
                    {props.t("Principale")}
                  </Link>
                </li>
                <li>
                  <Link to="/CalendrierExamenControle">{props.t("Contrôle")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/CalendrierDS2" className="">
                <i className="fas fa-calendar-alt"></i>
                <span>{props.t("Calendrier de devoir de contenu")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Demands" className="">
                <i className="fas fa-solid fa-clipboard-list"></i>
                <span>{props.t("Demandes")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="fas fa-graduation-cap"></i>
                <span>{props.t("Note examen")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Result">{props.t("Principale")}</Link>
                </li>
                <li>
                  <Link to="/ResultControle">{props.t("Contrôle")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="fas fa-graduation-cap"></i>
                <span>{props.t("Resultat")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Documents">{props.t("Principale")}</Link>
                </li>
                <li>
                  <Link to="/dashboard">{props.t("Contrôle")}</Link>
                </li>
              </ul>
            </li>
            { niveau_id == 3 ? (
                <li>
                  <Link to="/DemandeStage" className="">
                    <i className="fas fa-solid fa-clipboard-list"></i>
                    <span>{props.t("Demandes et suivi de PFE")}</span>
                  </Link>
                </li>
            ): (null)}
            { niveau_id == 2 && cycle_id == 2 ? (
                <li>
                  <Link to="/DemandeStage" className="">
                    <i className="fas fa-solid fa-clipboard-list"></i>
                    <span>{props.t("Demande mémoire de mastère")}</span>
                  </Link>
                </li>
            ): (null)}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
